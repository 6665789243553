import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeSponsorLogo = {
  sponsor_logo: PrismicImage;
};

export type TypeSessionBreak = {
  after_session_number: number;
  break_name: PrismicRichText;
};

export type TypeLineUpItem = {
  session_teaser_image: PrismicImage;
  session_pre_title: PrismicRichText;
  session_title: PrismicRichText;
  session_description: PrismicRichText;
};

export type TypeComposablenowEventPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  event_menu: string;
  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;

  speaker_lineup: TypeLineUpItem[];
  session_breaks: TypeSessionBreak[];
  cta_title: string;
  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;

  hero_image: PrismicImage;
  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;

  about_text: PrismicRichText;
  sponsors: TypeSponsorLogo[];
  schedule_text: PrismicRichText;
  schedule_image: PrismicImage;
  venue_text: PrismicRichText;
  venue_image: PrismicImage;
  faq_text: PrismicRichText;
};

export type TypeComposablenowEventQueryResult = {
  allPrismicComposablenowEvent: {
    edges: Array<{
      node: { data: TypeComposablenowEventPage };
    }>;
  };
};

export const getPage = (
  data: TypeComposablenowEventQueryResult
): TypeComposablenowEventPage => {
  return data.allPrismicComposablenowEvent.edges[0].node.data;
};
